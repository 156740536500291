import { useState } from "react";
import { createDateFromYYYYMM, formatDateYYYYMM } from "@icarius-utils/date";
import * as yup from 'yup';

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if(fieldName === 'Período'){
        return isCreate ? null : createDateFromYYYYMM(data[fieldName]);
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      'Período',
      'IPC',
      'UF Último día mes anterior',
      'UF Último día mes actual',
      'UF del día 9',
      'UTM',
      'Tope imponible',
      'Tope imponible seguro cesantía',
      'Salario mínimo',
      'Salario Grado 1-A',
      'Salario no remunerado',
      'Salario vital',
      'Dólar',
      'Euro',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      'Período': yup.string().required(),
      'IPC': yup.string(),
      'UF Último día mes anterior': yup.string().required(),
      'UF Último día mes actual': yup.string().required(),
      'UF del día 9': yup.string(),
      'UTM': yup.string().required(),
      'Tope imponible': yup.string().required(),
      'Tope imponible seguro cesantía': yup.string().required(),
      'Salario mínimo': yup.string().required(),
      'Salario Grado 1-A': yup.string(),
      'Salario no remunerado': yup.string(),
      'Salario vital': yup.string(),
      'Dólar': yup.string(),
      'Euro': yup.string(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      let dataToSend = {
        ...formData,
        'Período': formatDateYYYYMM(formData['Período']),
      };

      isCreate ? createCallback(dataToSend) : modifyCallback(dataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const formatValue = (value, fieldName) => {
    if(fieldName === "Período"){
      return value;
    }

    if(value.indexOf('.') > 0){
      return value.substring(0, value.indexOf('.') + 4);
    }
    
    return value;
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: formatValue(value, fieldName),
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
