import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRatingScalesAction } from "../actions";
import { getLoading, getData, getTypeList } from "../selectors";
import { getAppColor } from "src/app/selectors";
import { RESET_STATE } from "../actionTypes";
import { getFiltersIsLoading } from "@icarius-common/filtersDialog/selectors";
import { getTheme } from "@icarius-pages/login/selectors";

const useRatingScales = () => {

  const [createRatingScaleIsOpen, setCreateRatingScaleIsOpen] = useState(false);
  const typeList = useSelector(getTypeList);
  const dispatch = useDispatch();
  const gridTheme = useSelector(getTheme);

  useEffect(() => {
    dispatch(getRatingScalesAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])

  const color = useSelector(getAppColor);
  const data = useSelector(getData);
  const isLoadingLocal = useSelector(getLoading);
  const filtersIsLoading = useSelector(getFiltersIsLoading);
  const isLoading = isLoadingLocal || filtersIsLoading;

  return {
    gridTheme,
    color,
    data,
    typeList,
    isLoading,
    createRatingScaleIsOpen,
    setCreateRatingScaleIsOpen,
  }
}

export default useRatingScales;