import { useState } from "react";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { deleteQueryDefinitionAction, duplicateQueryDefinitionAction } from "../actions";
import { useHistory } from "react-router-dom";
import paths from "@icarius-localization/paths";

const useABM = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const [duplicateDialogIsOpen, setDuplicateDialogIsOpen] = useState(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [createEditDialogIsOpen, setCreateEditDialogIsOpen] = useState(false);
  const [codeToDelete, setCodeToDelete] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleOpenCreateDialog = () => {
    setCreateEditDialogIsOpen(true);
  }

  const handleOpenDuplicateDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un unico item", severity: "warning" }));
      return;
    }

    setDuplicateDialogIsOpen(true);
    setSelectedItem(selectedRows[0]);
  };

  const handleCloseDuplicateDialog = () => {
    setDuplicateDialogIsOpen(false);
    setSelectedItem(null);
  }

  const handleOpenEditDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un unico item", severity: "warning" }));
      return;
    }

    setCreateEditDialogIsOpen(true);
    setSelectedItem(selectedRows[0]);
  };

  const handleCloseEditDialog = () => {
    setCreateEditDialogIsOpen(false);
    setSelectedItem(null);
  }

  const handleOpenDeleteDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un unico item", severity: "warning" }));
      return;
    }

    setDeleteDialogIsOpen(true);
    setCodeToDelete(selectedRows.map(item => item.code)[0]);
  }

  const handleCloseDeleteDialog = () => {
    setDeleteDialogIsOpen(false);
    setCodeToDelete(null);
  }

  const handleDelete = () => {
    dispatch(deleteQueryDefinitionAction({ code: codeToDelete }))
      .then(() => {
        handleCloseDeleteDialog();
      })
  }

  const handleDuplicate = (data) => {
    dispatch(duplicateQueryDefinitionAction(data))
      .then(() => {
        handleCloseDuplicateDialog();
      })
  }

  const handleExecute = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un unico item", severity: "warning" }));
      return;
    }

    if (selectedRows[0].active !== 'Y') {
      dispatch(openSnackbarAction({ msg: "La consulta debe estar activa", severity: "warning" }));
      return;
    }

    const group = selectedRows[0].group;
    const queryCode = selectedRows[0].code;

    history.push({
      pathname: paths.queries,
      search: `?group=${group}&queryCode=${queryCode}`,
    });
  }

  return {
    deleteDialogIsOpen,
    createEditDialogIsOpen,
    duplicateDialogIsOpen,
    selectedItem,
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseEditDialog,
    handleOpenDeleteDialog,
    handleCloseDeleteDialog,
    handleOpenDuplicateDialog,
    handleCloseDuplicateDialog,
    handleDelete,
    handleDuplicate,
    handleExecute,
  }
}

export default useABM;
