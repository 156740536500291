import paths from "@icarius-localization/paths";

export default [
    {
        pages: [paths.queryDefinitionParameters],
        config: {
            headerName: "Código de consulta",
            field: "queryCode",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.queryDefinitionParameters],
        config: {
            headerName: "Número de parámetro",
            field: "paramNum",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.queryDefinitionParameters],
        config: {
            headerName: "Nombre del parámetro",
            field: "name",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.queryDefinitionParameters],
        config: {
            headerName: "Tipo de parámetro",
            field: "paramTypeString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.queryDefinitionParameters],
        config: {
            headerName: "Obligatorio",
            field: "requireString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.queryDefinitionParameters],
        config: {
            headerName: "Tipo de selector para lista de parámetros",
            field: "listTypeString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.queryDefinitionParameters],
        config: {
            headerName: "SQL para determinar la lista de valores para el parámetro",
            field: "sql",
            filter: "agTextColumnFilter",
        }
    },
]