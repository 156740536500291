import React from "react";
import { useDispatch } from "react-redux";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import FabButton from "@icarius-common/fabButton";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import RatingDetail from "./ratingDetail";
import { createRatingScalesAction, modifyRatingScalesAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { yesNoArray } from "@icarius-utils/constants";

const gridStyle = { height: 75, padding: "0px 10px" };

const Form = (props) => {

  const {
    data,
    isCreate,
    ratingScaleNames,
    gridTheme,
    typeList,
    ratingScaleCodes,
    closeRatingScale,
  } = props;

  const dispatch = useDispatch();

  const createRatingScale = (dataToSend) => {
    dispatch(createRatingScalesAction(dataToSend))
      .then((res) => res?.status === 200 && closeRatingScale());
  }

  const modifyRatingScale = (dataToSend) => {
    dispatch(modifyRatingScalesAction(dataToSend))
      .then((res) => res?.status === 200 && closeRatingScale());
  }

  const openValidationError = (error) => {
    let msg = "Todos los campos son obligatorios y debe haber al menos una calificación";
    if (error) {
      msg = error;
    }
    dispatch(openSnackbarAction({ msg, severity: "error", duration: 10000 }));
  }

  const openNameValidationError = () => {
    dispatch(openSnackbarAction({ msg: 'Ya existe un tipo de escala con el nombre ingresado', severity: "error" }));
  }

  const openCodeValidationError = () => {
    dispatch(openSnackbarAction({ msg: 'Ya existe un tipo de escala con el código ingresado', severity: "error" }));
  }

  const invalidDetailsNamesCallback = () => {
    dispatch(openSnackbarAction({ msg: 'No puede haber calificaciones con el mismo nombre', severity: "error" }));
  }

  const {
    formData,
    setFormValue,
    createItem,
    deleteItem,
    modifyItem,
    submit,
  } = useHandleForm(data, createRatingScale, modifyRatingScale, openValidationError, openNameValidationError, ratingScaleNames, ratingScaleCodes, openCodeValidationError, invalidDetailsNamesCallback);

  return (
    <>
      <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
        <Grid container item xs={12}>
          <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}>
            <TextField
              disabled={!isCreate}
              required
              fullWidth
              margin={"none"}
              label={"Código del tipo de escala"}
              value={formData.code}
              onChange={(e) => setFormValue(e.target.value, "code")}
              inputProps={{ maxLength: 8 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}>
            <TextField
              required
              fullWidth
              margin={"none"}
              label={"Nombre del tipo de escala"}
              value={formData.name}
              onChange={(e) => setFormValue(e.target.value, "name")}
              inputProps={{ maxLength: 60 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={3} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label`}>{"Calificación a utilizar"}</InputLabel>
              <Select
                value={formData.type}
                labelId={`label`}
                id={`select`}
                onChange={(e) => setFormValue(e.target.value, "type")}
                margin={"none"}
              >
                {
                  typeList?.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={3} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label`}>{"Utiliza color?"}</InputLabel>
              <Select
                disabled={formData.type === 'E'}
                value={formData.useColor}
                labelId={`label`}
                id={`select`}
                onChange={(e) => setFormValue(e.target.value, "useColor")}
                margin={"none"}
              >
                {
                  yesNoArray.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          {
            formData.details?.map((item, index) =>
              <RatingDetail
                inUse={data?.inUse}
                key={index}
                index={index}
                gridTheme={gridTheme}
                data={item}
                deleteItem={deleteItem}
                modifyItem={modifyItem}
                formData={formData}
              />
            )
          }
        </Grid>
        <Grid container item xs={12} justify={"space-between"} alignItems="center" style={{ paddingBottom: 20 }}>
          <FabButton onClick={createItem} title={"Nueva calificación"} />
          <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
        </Grid>
      </Grid>
    </>
  );
}

export default Form;
