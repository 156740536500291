import React from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import useRatings from "./useRatings";
import DuplicateDialog from "./dialogs/duplicateDialog/duplicateDialog";
import CreateEditDialog from "./dialogs/createEditDialog/index";

const Ratings = ({ history }) => {

  const {
    data,
    isLoading,
    state,
    handlers
  } = useRatings();

  const createButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={() => handlers.handleOpenCreateDialog(gridRef)}
      type={"add"}
    />
  )

  const modifyButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />
  )

  const deleteButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />
  )

  const duplicateButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("duplicate")}
      onClick={() => handlers.handleOpenDuplicateDialog(gridRef)}
      type={"duplicate"}
    />
  )

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => history.push(paths.ratingsAndGeneralValues)}
      type={"goBack"}
    />
  )

  return (
    <CommonPage
      isLoading={isLoading}
      title={'Índices'}
      gridTitle={'Índices'}
      columnDefPage={paths.ratings}
      rowData={data}
      menuItems={[goBackButton, createButton, modifyButton, duplicateButton, deleteButton]}
      hasExpand
      hasHelp
    >
      {
        (state.createDialogIsOpen || state.editDialogIsOpen) &&
        <CreateEditDialog
          open={(state.createDialogIsOpen || state.editDialogIsOpen)}
          isLoading={isLoading}
          data={state.selectedDocument}
          handleClose={handlers.handleCloseCreateEditDuplicateDialog}
        />
      }
      {
        state.duplicateDialogIsOpen && !isLoading &&
        <DuplicateDialog
          open={state.duplicateDialogIsOpen}
          data={state.selectedDocument}
          handleConfirm={handlers.handleDuplicate}
          handleClose={handlers.handleCloseCreateEditDuplicateDialog}
        />
      }
    </CommonPage>
  )
}

export default Ratings;