import paths from "@icarius-localization/paths";
import {
	numberComparatorMax2,
	numberFormatterMin2,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.ratings],
        config: {
            headerName: 'Período',
            field: "Período",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.ratings],
        config: {
            headerName: 'IPC',
            field: "IPC",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.ratings],
        config: {
            headerName: 'UF Último día mes anterior',
            field: "UF Último día mes anterior",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.ratings],
        config: {
            headerName: 'UF Último día mes actual',
            field: "UF Último día mes actual",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.ratings],
        config: {
            headerName: 'UF del día 9',
            field: "UF del día 9",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.ratings],
        config: {
            headerName: 'UTM',
            field: "UTM",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.ratings],
        config: {
            headerName: 'Tope imponible',
            field: "Tope imponible",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.ratings],
        config: {
            headerName: 'Tope imponible seguro cesantía',
            field: "Tope imponible seguro cesantía",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.ratings],
        config: {
            headerName: 'Salario mínimo',
            field: "Salario mínimo",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.ratings],
        config: {
            headerName: 'Salario Grado 1-A',
            field: "Salario Grado 1-A",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.ratings],
        config: {
            headerName: 'Salario no remunerado',
            field: "Salario no remunerado",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.ratings],
        config: {
            headerName: 'Salario vital',
            field: "Salario vital",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.ratings],
        config: {
            headerName: 'Dólar',
            field: "Dólar",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.ratings],
        config: {
            headerName: 'Euro',
            field: "Euro",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
]