import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createQueryDefinitionAction, modifyQueryDefinitionAction } from "../../actions";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { withStyles } from "@material-ui/core/styles";
import { getActiveTypes, getDataTypes, getRoleTypes } from "../../selectors";
import { AddIcon } from "@icarius-icons";
import { getAppColor } from "src/app/selectors";

const gridStyle = { height: 75, padding: "0px 10px" };

const SelectorButton = ({ text, onClick }) => {

  const color = useSelector(getAppColor);

  return (
    <Button
      style={{ margin: 5 }}
      variant={"outlined"}
      disableRipple={true}
      onClick={onClick}>
      <AddIcon htmlColor={color} />
      {text}
    </Button>
  )
}

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },

  cssFocused: {
    color: "var(--mainText) !important",
  },

  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});

const QueryForm = (props) => {

  const {
    data,
    handleClose,
    classes,
  } = props;

  const dispatch = useDispatch();

  const activeTypes = useSelector(getActiveTypes);
  const roleTypes = useSelector(getRoleTypes);
  const dataTypes = useSelector(getDataTypes);

  const create = (formData) => {
    dispatch(createQueryDefinitionAction(formData))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (formData) => {
    dispatch(modifyQueryDefinitionAction(formData))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const openSelectorValidationError = () => {
    dispatch(openSnackbarAction({ msg: "Selectores invalidos", severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, create, modify, dataTypes, openValidationError, openSelectorValidationError);

  return (
    <Grid container item xs={12} direction="row" style={{ width: "95%", margin: "auto" }}>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* codigo */}
          <TextField
            disabled={!Boolean(isCreate)}
            required
            fullWidth
            margin={"none"}
            label={"Codigo"}
            onChange={(e) => setFormValue(e.target.value, "code")}
            value={formData.code}
            inputProps={{ maxLength: 20 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* nombre */}
          <TextField
            required
            fullWidth
            margin={"none"}
            label={"Nombre"}
            value={formData.name}
            onChange={(e) => setFormValue(e.target.value, "name")}
            inputProps={{ maxLength: 254 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* Activa */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-active`}>{"Activa"}</InputLabel>
            <Select
              value={formData.active}
              labelId={`label-active`}
              id={`select-active`}
              onChange={(e) => setFormValue(e.target.value, "active")}
              margin={"none"}
            >
              {
                activeTypes && activeTypes.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}> {/* sql */}
          <TextField
            required
            fullWidth
            label="SQL"
            variant="outlined"
            multiline
            rows={25}
            margin={"none"}
            value={formData.sql}
            onChange={(e) => setFormValue(e.target.value, "sql")}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              }
            }}
            inputProps={{ spellCheck: 'false' }}
          />
        </Grid>
        <Grid container item alignItems="center" sm={12} md={6} style={{ ...gridStyle, marginTop: 12 }}> {/* group */}
          <TextField
            required
            fullWidth
            margin={"none"}
            label={"Grupo de ubicación"}
            value={formData.group}
            onChange={(e) => setFormValue(e.target.value, "group")}
            inputProps={{ maxLength: 100 }}
            helperText={'Grupos de ubicación especiales: Mi personal, Maestro de Personas, Datos Personales, Menú del usuario y Test'}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* role */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-rol`}>{"Rol de visualización"}</InputLabel>
            <Select
              value={formData.rol}
              labelId={`label-role`}
              id={`select-role`}
              onChange={(e) => setFormValue(e.target.value, "rol")}
              margin={"none"}
            >
              {
                roleTypes && roleTypes.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={2} style={gridStyle}> {/* secuencia */}
          <TextField
            required
            fullWidth
            margin={"none"}
            label={'Secuencia de aparición'}
            type="number"
            value={formData.sequence}
            onChange={(e) => setFormValue(e.target.value.substring(0, 3), "sequence")}
            inputProps={{ min: 1, max: 999, maxLength: 3 }}
            onBlur={(e) => e.target.value = e.target.value.substring(0, 3)}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} style={{ padding: "0px 10px", paddingTop: 20 }}> {/* selectores */}
          <TextField
            required
            fullWidth
            helperText={
              `Para cada columna de SQL, los tipos de selectores pueden ser: ${dataTypes.map((item) => item.key).join(', ')}`
            }
            margin={"none"}
            label={"Tipos de Selectores por columnas"}
            value={formData.selectors}
            onChange={(e) => setFormValue(e.target.value, "selectors")}
          />
        </Grid>
        <Grid container item alignItems="center" justify="center" xs={12} style={{ marginTop: 10 }}>
          {
            dataTypes.map((item) => {
              return (
                <SelectorButton
                  key={item.key}
                  text={item.value}
                  onClick={() => setFormValue(`${formData.selectors},${item.key}`, "selectors")}
                />
              )
            })
          }
        </Grid>
      </Grid>
      <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}>
        <TextField
          fullWidth
          label="SQL para gestión del contexto"
          variant="outlined"
          multiline
          rows={10}
          margin={"none"}
          value={formData.sqlContext}
          onChange={(e) => setFormValue(e.target.value, "sqlContext")}
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused,
            },
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline,
            }
          }}
          inputProps={{ spellCheck: 'false' }}
        />
      </Grid>
      <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}>
        <TextField
          fullWidth
          label="Comentarios del contexto"
          variant="outlined"
          multiline
          rows={10}
          margin={"none"}
          value={formData.comment}
          onChange={(e) => setFormValue(e.target.value, "comment")}
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused,
            },
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline,
            }
          }}
          inputProps={{ spellCheck: 'false' }}
        />
      </Grid>
      <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ marginBottom: 35 }}>
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(QueryForm);