import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQueryDefinitionAction } from "../actions";
import { getRows, getLoading, getDateFormat } from "../selectors";
import { CLEAR_STATE } from "../actionTypes";
import { getIsExportingGrid } from "src/app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import FormContainer from "./form/formContainer";
import ConfirmationDialog from "./dialogs/confirmationDialog";
import DuplicateDialog from "./dialogs/duplicateDialog";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import useABM from "./useABM";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import SearchConcept from "./searchConcept";

const QueryDefinition = ({ handleOpenParameterPage, handleGoBack }) => {

  const dispatch = useDispatch();

  const data = useSelector(getRows);
  const dateFormat = useSelector(getDateFormat);
  const isLoading = useSelector(getLoading);
  const isExportinGrid = useSelector(getIsExportingGrid);

  useEffect(() => {
    dispatch(getQueryDefinitionAction());
    return () => {
      dispatch({ type: CLEAR_STATE });
    }
  }, [dispatch])

  const {
    deleteDialogIsOpen,
    createEditDialogIsOpen,
    duplicateDialogIsOpen,
    selectedItem,
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseEditDialog,
    handleOpenDeleteDialog,
    handleCloseDeleteDialog,
    handleOpenDuplicateDialog,
    handleCloseDuplicateDialog,
    handleDelete,
    handleDuplicate,
    handleExecute,
  } = useABM();

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handleGoBack}
      type={"goBack"}
    />

  const addItem = () =>
    <CustomIconButton
      title={"Crear"}
      onClick={() => handleOpenCreateDialog()}
      type={"add"}
    />

  const deleteItem = (gridRef) =>
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />

  const editItem = (gridRef) =>
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handleOpenEditDialog(gridRef)}
      type={"edit"}
    />

  const duplicateItem = (gridRef) =>
    <CustomIconButton
      title={getLocalizedString("duplicate")}
      onClick={() => handleOpenDuplicateDialog(gridRef)}
      type={"duplicate"}
    />

  const executeItem = (gridRef) =>
    <CustomIconButton
      title={'Ejecutar consulta'}
      onClick={() => handleExecute(gridRef)}
      type={"execute"}
    />

  const handleRowClick = (row) => {
    handleOpenParameterPage(row.data);
  }

  const helpItems = [
    <MenuItemWithIcon
      key={"b"}
      value={"/resources/ICARIUS - Definición de gestión (Consultas-GeoAnálisis-KPI).pdf"}
      text={'Definición de gestión (Consultas-GeoAnálisis-KPI)'}
      type={"file"}
    />,
  ]

  const searchItem = (ref) => <SearchConcept gridRef={ref} data={data} />

  return (
    <>
      {
        createEditDialogIsOpen ?
          <FormContainer
            isLoading={isLoading}
            data={selectedItem}
            handleClose={handleCloseEditDialog}
          />
          :
          <CommonPage
            columnDefPage={paths.queryDefinition}
            title={"Definición de consultas"}
            gridTitle={"Definición de consultas"}
            menuItems={[goBackItem, searchItem, addItem, editItem, deleteItem, duplicateItem, executeItem]}
            helpItems={helpItems}
            rowData={data}
            dateFormat={dateFormat}
            isLoading={isExportinGrid || isLoading}
            handleRowClick={handleRowClick}
            hasExpand
            hasHelp
          >
            {
              deleteDialogIsOpen && !isLoading &&
              <ConfirmationDialog
                open={deleteDialogIsOpen}
                title={getLocalizedString("atention")}
                text={"¿Está seguro que desea eliminar la definición de consulta?"}
                handleConfirm={handleDelete}
                handleClose={handleCloseDeleteDialog}
              />
            }
            {
              duplicateDialogIsOpen && !isLoading &&
              <DuplicateDialog
                open={duplicateDialogIsOpen}
                data={selectedItem}
                handleConfirm={handleDuplicate}
                handleClose={handleCloseDuplicateDialog}
              />
            }
          </CommonPage>
      }
    </>
  );
}

export default QueryDefinition;
