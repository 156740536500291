import * as actionTypes from "./actionTypes";

const initialState = {
  data: [],
  typeList: [],
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_TYPES_OF_SCORE:
      return { ...state, isLoading: true };
    case actionTypes.GET_TYPES_OF_SCORE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        typeList: action.payload.typeList,
      };
    case actionTypes.GET_TYPES_OF_SCORE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DELETE_TYPES_OF_SCORE:
      return { ...state, isLoading: true };
    case actionTypes.DELETE_TYPES_OF_SCORE_FULFILLED:
      return { ...state, isLoading: false, data: action.payload.data };
    case actionTypes.DELETE_TYPES_OF_SCORE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.CREATE_TYPES_OF_SCORE:
      return { ...state, isLoading: true };
    case actionTypes.CREATE_TYPES_OF_SCORE_FULFILLED:
      return { ...state, isLoading: false, data: action.payload.data };
    case actionTypes.CREATE_TYPES_OF_SCORE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.MODIFY_TYPES_OF_SCORE:
      return { ...state, isLoading: true };
    case actionTypes.MODIFY_TYPES_OF_SCORE_FULFILLED:
      return { ...state, isLoading: false, data: action.payload.data };
    case actionTypes.MODIFY_TYPES_OF_SCORE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
