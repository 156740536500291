import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { IconButton, Grid, Card, Tooltip } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { DeleteIcon } from "@icarius-icons";
import Form from "./form";
import { deleteRatingScalesAction } from "../../actions";
import DeleteDialog from "../dialogs/deleteDialog";
import DataContainer from "@icarius-common/abmComponents/dataContainer";

const Item = (props) => {

  const {
    data,
    ratingScaleNames,
    roles,
    modes,
    typeList,
    ratingScaleTypes,
    ratingScaleCodes,
    color,
    gridTheme,
    closeNewRatingScale,
  } = props;

  const dispatch = useDispatch();

  const isCreate = !Boolean(data);

  const [isOpen, setIsOpen] = useState(isCreate); //si viene sin data, que este abierto por defecto
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);

  const handleDeleteRatingScale = () => {
    if (isCreate) {
      closeNewRatingScale();
    } else {
      dispatch(deleteRatingScalesAction(data.code, data.name));
    }
  }

  const handleCloseRatingScale = () => {
    Boolean(data) && setIsOpen(prev => !prev);
  }

  return (
    <>
      <Card style={{ width: "100%", borderRadius: 10, background: (isOpen || isCreate) ? color : "", color: (isOpen || isCreate) && 'white' }}>
        <Grid container item xs={12} direction="row" style={{ padding: "10px 20px" }}>
          <Grid container item xs={10} style={{ cursor: Boolean(data) ? "pointer" : "" }} onClick={handleCloseRatingScale}>
            {
              isCreate ?
                <DataContainer sm={12} md={12} lg={12} text={"Creación de tipo de escala"} />
                :
                <>
                  <DataContainer lg={2} md={2} sm={4} text={data.code} />
                  <DataContainer lg={4} md={4} sm={4} text={data.name} />
                  <DataContainer lg={4} md={4} sm={4} text={`Calificación a utilizar: ${data.typeString}`} />
                  <DataContainer lg={2} md={2} sm={4} text={`Calificaciones: ${parseInt(data.details.length)}`} />
                </>
            }
          </Grid>
          <Grid container direction="row" item xs={2} justify={isCreate ? "flex-end" : "space-around"}>
            <Tooltip title={getLocalizedString("delete")}>
              <IconButton size={"small"} disableRipple onClick={() => setDeleteDialogIsOpen(true)}>
                <DeleteIcon style={{ fill: "var(--icons)" }} fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Card>
      {
        isOpen &&
        <Form
          closeRatingScale={isCreate ? closeNewRatingScale : handleCloseRatingScale}
          data={data}
          roles={roles}
          modes={modes}
          isCreate={isCreate}
          gridTheme={gridTheme}
          typeList={typeList}
          ratingScaleTypes={ratingScaleTypes}
          ratingScaleNames={ratingScaleNames}
          ratingScaleCodes={ratingScaleCodes}
        />
      }
      {
        deleteDialogIsOpen &&
        <DeleteDialog
          open={deleteDialogIsOpen}
          ratingScale={data}
          handleClose={() => setDeleteDialogIsOpen(false)} handleDelete={handleDeleteRatingScale}
        />
      }
    </>
  );
}

export default Item;
