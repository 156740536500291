import * as actionTypes from "./actionTypes";
import { getRatingScalesAPI, deleteRatingScalesAPI, createRatingScalesAPI, modifyRatingScalesAPI } from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "IN_USE_FORM":
      errorString = 'No es posible eliminar la escala para calificación porque está en uso en los formularios de desempeño';
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getRatingScalesAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_TYPES_OF_SCORE });
  try {
    let response = await getRatingScalesAPI();

    let data = response.data && response.data.data;
    let typeList = response.data && response.data.typeList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_TYPES_OF_SCORE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_TYPES_OF_SCORE_FULFILLED,
      payload: {
        data,
        typeList
      }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_TYPES_OF_SCORE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteRatingScalesAction = (code, ratingScaleName) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_TYPES_OF_SCORE });
  try {
    let response = await deleteRatingScalesAPI({ code });


    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_TYPES_OF_SCORE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_TYPES_OF_SCORE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: getLocalizedString("ratingScaleDeletedOK"), severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_TYPES_OF_SCORE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createRatingScalesAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_TYPES_OF_SCORE });
  try {
    let response = await createRatingScalesAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_TYPES_OF_SCORE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_TYPES_OF_SCORE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: getLocalizedString("ratingScaleCreatedOK"), severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_TYPES_OF_SCORE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const modifyRatingScalesAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY_TYPES_OF_SCORE });
  try {
    let response = await modifyRatingScalesAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_TYPES_OF_SCORE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_TYPES_OF_SCORE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: getLocalizedString("ratingScaleModifiedOK"), severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_TYPES_OF_SCORE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};