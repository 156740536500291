import { useState } from "react";
import * as yup from 'yup';

const DETAILS_ERRORS = {
  "INCOMPLETE_FIELDS": "Verifique que todos los campos esten completos",
  "LESS_THAN_ONE": "El valor mínimo de la calificación es 0,1",
  "EXCEEDS_MAX_VALUE": "El valor numérico de la calificación debe ser menor a 10000",
  "VALUES_DO_NOT_MATCH": "Verifique que los valores de las calificaciones tengan orden creciente",
  "NAME_LENGTH_GREATER_THAN": "El nombre de la calificación no puede tener más de 60 caracteres",
}

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback, invalidNameCallback, ratingScaleNames, ratingScaleCodes, invalidCodeCallback, invalidDetailsNamesCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {
    const getInitialFieldData = (fieldName) => {

      if (fieldName === 'type') {
        return isCreate ? "N" : data[fieldName];
      }

      if (fieldName === 'useColor') {
        return isCreate ? "N" : (data[fieldName] ? "Y" : "N");
      }

      if (fieldName === 'details') {
        return isCreate ? [] : data[fieldName];
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      "code",
      "name",
      "type",
      "useColor",
      "details",
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const {
    code,
    name,
    type,
    useColor,
    details,
  } = formData;

  const detailsNameIsValid = () => {
    const counts = {};
    details.map(el => el.name).forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
    if (Object.keys(counts).length !== details.length) {
      return false;
    }

    return true;
  }

  const detailsAreValid = () => {
    if (details.length === 0) return false;

    // Primero reviso si tengo un número par de repetidos
    for (let i = 0; i < details.length; i++) {
      const { name, value, color, criterion } = details[i];

      if (name.length > 60) {
        return DETAILS_ERRORS.NAME_LENGTH_GREATER_THAN;
      }

      // Verifico tener los campos necesarios
      if (
        !name ||
        (value === null || value === undefined || value === "") ||
        (useColor === "Y" && !color) ||
        (type === "C" && !criterion)
      ) {
        return DETAILS_ERRORS.INCOMPLETE_FIELDS;
      }

      if (parseFloat(value) < 0.1) {
        return DETAILS_ERRORS.LESS_THAN_ONE;
      }

      if (parseFloat(value) > 9999.9) {
        return DETAILS_ERRORS.EXCEEDS_MAX_VALUE;
      }

      if (i !== (details.length - 1)) {
        if (parseFloat(details[i + 1].value) <= parseFloat(value)) {
          return DETAILS_ERRORS.VALUES_DO_NOT_MATCH;
        }
      }
    }

    return true;
  }

  const codeRegex = /^([A-Z])([A-Z]|[0-9]|_){0,8}$/;

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      'code': yup.string().matches(codeRegex).max(8).required(),
      'name': yup.string().required().max(60),
      'useColor': yup.string().required().max(20),
      'type': yup.string().required().max(20),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const nameIsValid = () => {
    if (!isCreate && data.name === name) return true;
    return !(ratingScaleNames.includes(name));
  }

  const codeIsValid = () => {
    if (!isCreate) return true;
    return !(ratingScaleCodes.includes(code));
  }

  const submit = async () => {
    if (await dataIsValid()) {
      if (detailsAreValid() === true) {
        if (codeIsValid()) {
          if (nameIsValid()) {
            if (detailsNameIsValid()) {
              const dataToSend = {
                code,
                name,
                type,
                useColor: useColor === "Y",
              }

              if (!isCreate) {
                dataToSend.code = data.code;
              }

              dataToSend.details = details.map((item) => {
                const newFormattedDetail = {
                  name: item.name,
                  value: parseFloat(item.value),
                  color: item.color,
                  criterion: item.criterion,
                };

                return newFormattedDetail;
              });

              isCreate ? createCallback(dataToSend) : modifyCallback(dataToSend);
              return true;
            } else {
              invalidDetailsNamesCallback();
              return false;
            }
          } else {
            invalidNameCallback();
            return false;
          }
        } else {
          invalidCodeCallback();
          return false;
        }
      } else {
        invalidDataCallback(detailsAreValid());
        return false;
      }
    } else {
      if (code.length > 8) {
        invalidDataCallback("El código no puede exceder los 8 caracteres");
      } else if (name.length > 60) {
        invalidDataCallback("El nombre no puede exceder los 60 caracteres");
      } else if (!code.match(codeRegex)) {
        invalidDataCallback("El código ingresado es inválido");
      }
      return false;
    }
  }

  const createItem = () => {
    //agregar el objeto default a details
    const newEmptyDetail = {
      name: "",
      value: "",
      color: "",
      criterion: "",
    }

    setFormValue([...details, newEmptyDetail], 'details');
  }

  const deleteItem = (index) => {
    const newDetails = [...details];
    newDetails.splice(index, 1);
    setFormValue(newDetails, 'details');
  }

  const modifyItem = (index, value, fieldName) => {
    const updatedDetail = { ...details[index] };
    updatedDetail[fieldName] = value;

    const newDetails = [...details];
    newDetails.splice(index, 1, updatedDetail);
    setFormValue(newDetails, 'details');
  }


  const setFormValue = (value, fieldName) => {
    if (fieldName === "type" && value !== "N" && value !== "V") {
      setFormData({
        ...formData,
        [fieldName]: value,
        "useColor": "N"
      })
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return {
    isCreate,
    formData,
    setFormValue,
    createItem,
    deleteItem,
    modifyItem,
    submit,
  };
}

export default useHandleForm;