import React, { useState } from "react";
import { Tooltip, IconButton, TextField, Grid, Typography } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { DeleteIcon } from "@icarius-icons";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ChangeColorDialog from "../dialogs/changeColorDialog";
import { withStyles } from "@material-ui/core/styles";

const gridStyle = { height: 75, padding: "0px 10px" };

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },

  cssFocused: {
    color: "var(--mainText) !important",
  },

  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});

const RatingDetail = (props) => {

  const {
    inUse,
    index,
    gridTheme,
    data,
    deleteItem,
    modifyItem,
    classes,
    formData
  } = props;

  const [changeColorDialogIsOpen, setChangeColorDialogIsOpen] = useState(false);

  return (
    <>
      <div style={{ width: "100%" }}>
        <DialogTitleDivider />
      </div>
      <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
        <Grid container item xs={11}>
          <Grid container item alignItems="center" xs={12} sm={4} md={4} style={gridStyle}>
            <TextField
              disabled={inUse}
              required
              fullWidth
              margin={"none"}
              label={"Nombre de la calificación"}
              value={data.name}
              onChange={(e) => modifyItem(index, e.target.value, 'name')}
              inputProps={{ maxLength: 60 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={4} md={4} style={gridStyle}>
            <TextField
              disabled={inUse}
              fullWidth
              required
              margin={"none"}
              label={'Valor numérico de la calificación'}
              type="number"
              value={data.value}
              onChange={(e) => modifyItem(index, e.target.value, 'value')}
              inputProps={{ min: "0.1", step: "0.1" }}
              onBlur={e => {
                if (e.target.value !== "") {
                  e.target.value = Number.parseFloat(e.target.value).toFixed(1);
                  modifyItem(index, e.target.value, 'value');
                }
              }}
            />
          </Grid>
          {
            formData.useColor === "Y" &&
            <Grid container item alignItems="center" xs={12} sm={3} style={gridStyle}>
              <Typography className="whiteText" style={{ marginRight: formData.useColor === "N" ? 10 : 5 }}>{"Color de representación"}</Typography>
              <Typography variant="subtitle1" style={{ marginLeft: 5, marginRight: 10, display: "inline-block", color: "red" }}>
                *
              </Typography>
              <div
                onClick={() => setChangeColorDialogIsOpen(true)}
                style={{ border: gridTheme.theme === "dark" ? "1px solid #c4c4c4" : "1px solid black", width: 64, height: 24, backgroundColor: data.color }}
              />
            </Grid>
          }
          <Grid container item xs={formData.useColor === "Y" ? 1 : 4} alignItems="center" justify="flex-end">
            <Tooltip title={getLocalizedString("delete")}>
              <IconButton size={"small"} disableRipple onClick={() => deleteItem(index)}>
                <DeleteIcon style={{ fill: "var(--icons)" }} fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}>
            <TextField
              fullWidth
              label="Criterio de la calificación"
              variant="outlined"
              multiline
              required={formData.type === "C"}
              rows={5}
              margin={"none"}
              value={data.criterion}
              onChange={(e) => modifyItem(index, e.target.value, "criterion")}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                }
              }}
              inputProps={{ spellCheck: 'false' }}
            />
          </Grid>
        </Grid>
        {
          changeColorDialogIsOpen &&
          <ChangeColorDialog
            open={changeColorDialogIsOpen}
            scoreColor={data.color}
            handleCloseDialog={() => setChangeColorDialogIsOpen(false)}
            modifyItem={modifyItem}
            index={index}
          />
        }
      </Grid>
    </>
  );
}

export default withStyles(styles)(RatingDetail);