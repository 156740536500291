import paths from "@icarius-localization/paths";

export default [
    {
        pages: [paths.queryDefinition],
        config: {
            headerName: "Grupo de ubicación",
            field: "group",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.queryDefinition],
        config: {
            headerName: "Secuencia de aparición del grupo",
            field: "sequence",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.queryDefinition],
        config: {
            headerName: "Activa",
            field: "activeString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.queryDefinition],
        config: {
            headerName: "Código de consulta",
            field: "code",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.queryDefinition],
        config: {
            headerName: "Nombre",
            field: "name",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.queryDefinition],
        config: {
            headerName: "Rol de visualización",
            field: "rolString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.queryDefinition],
        config: {
            headerName: "Información de contexto",
            field: "hasContext",
            filter: "agSetColumnFilter",
        }
    },
]