import React from "react";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Grid,
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";
import PaperDraggable from "@icarius-common/paperDraggable";
import useHandleFormDuplicate from "./useHandleFormDuplicate";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";

const gridStyle = { height: 75, padding: "0px" };

const DuplicateDialog = (props) => {

  const { open, data, handleConfirm, handleClose } = props;

  const dispatch = useDispatch();

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    formData,
    setFormValue,
    submit
  } = useHandleFormDuplicate(data, handleConfirm, openValidationError);

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth
      maxWidth={"sm"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {`Duplicar indice: ${data['Período']}`}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ paddingTop: 0 }}>
        <Grid container item xs={12}>
          <Grid container item alignItems="center" xs={12} style={gridStyle}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <DatePicker
                required
                style={{ marginTop: 0, marginBottom: 0, width: "100%" }}
                label={'Período'}
                cancelLabel={getLocalizedString("cancel")}
                okLabel={getLocalizedString("ok")}
                invalidDateMessage=""
                format="yyyy/MM"
                margin="normal"
                value={formData['Período']}
                onChange={(e) => setFormValue(e, 'Período')}
                views={["year", "month"]}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
        <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
      </DialogActions>
    </Dialog>
  );
}

export default DuplicateDialog;
