import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import { Tooltip, Grid, TextField, InputAdornment } from "@material-ui/core";
import { SearchIcon } from "@icarius-icons/";

const SearchConcept = ({ gridRef, data }) => {
  const [searchInput, setSearchInput] = useState("");

  const color = useSelector(getAppColor);

  const handleSearch = () => {
    let filterComponent;
    filterComponent = gridRef.api.getFilterInstance("code");

    if (!Boolean(searchInput)) {
      filterComponent.resetFilterValues();
      gridRef.api.onFilterChanged();
      return;
    }

    const keys = data.filter(el => String(el["sql"]).toLowerCase().includes(String(searchInput).toLowerCase())).map(el => el["code"]);
    filterComponent.setModel({ values: keys });
    gridRef.api.onFilterChanged();
  }

  return (
    <Tooltip title={"Buscar en SQL"}>
      <Grid container alignItems="center" style={{ flexWrap: "nowrap", marginLeft: 25, marginRight: 15 }}>
        <TextField
          style={{ width: 200, marginRight: 5 }}
          margin={"none"}
          label={"Buscar en SQL"}
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSearch();
              e.preventDefault();
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ fill: color, cursor: "pointer" }} onClick={handleSearch} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Tooltip>
  );
}

export default SearchConcept;
