import React from "react";
import paths from "@icarius-localization/paths";
import ScreenSelector from "@icarius-common/screenSelector";
import {
  GeneralValuesIcon,
  FamilyAsignationsIcon,
  RatingsIcon,
  TaxTableIcon,
} from "@icarius-icons/";

const RatingsAndGeneralValues = () => {

  const data = [
    {
      name: "Valores Generales",
      icon: <GeneralValuesIcon />,
      path: paths.generalValues,
    },
    {
      name: "Asignaciones familiares",
      icon: <FamilyAsignationsIcon />,
      path: paths.familyAsignations,
    },
    {
      name: "Índices",
      icon: <RatingsIcon />,
      path: paths.ratings,
    },
    {
      name: "Tabla de impuestos",
      icon: <TaxTableIcon />,
      path: paths.taxTable,
    },
  ];

  return (
    <ScreenSelector
      title={"Índices y valores generales"}
      data={data}
    />
  );
}

export default RatingsAndGeneralValues;