import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getQueryDefinitionAPI,
  createQueryDefinitionAPI,
  modifyQueryDefinitionAPI,
  deleteQueryDefinitionAPI,
  duplicateQueryDefinitionAPI,
} from "@icarius-connection/api";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "DUPLICATED_DATA":
      errorString = "No puede existir más de una consulta que comparta el mismo código o nombre";
      break;
    case "IN_USE":
      errorString = "La consulta no puede ser eliminada porque está asociada a un proceso especial";
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getQueryDefinitionAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_ROWS });
  try {
    let response = await getQueryDefinitionAPI();

    let rows = response.data && response.data.data;
    let dataTypes = response.data && response.data.dataTypes;
    let roleTypes = response.data && response.data.rolTypes;
    let activeTypes = response.data && response.data.activeTypes;
    let dateFormat = response.data && response.data.dateFormat;

    dispatch({
      type: actionTypes.GET_ROWS_FULFILLED,
      payload: {
        rows,
        dataTypes,
        roleTypes,
        activeTypes,
        dateFormat,
      }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_ROWS_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createQueryDefinitionAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createQueryDefinitionAPI(data);

    let rows = response.data && response.data.data;
    let dataTypes = response.data && response.data.dataTypes;
    let roleTypes = response.data && response.data.rolTypes;
    let activeTypes = response.data && response.data.activeTypes;
    let dateFormat = response.data && response.data.dateFormat;

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: {
        rows,
        dataTypes,
        roleTypes,
        activeTypes,
        dateFormat,
      }
    });
    dispatch(openSnackbarAction({ msg: "Definición de consulta creada con éxito", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const modifyQueryDefinitionAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await modifyQueryDefinitionAPI(data);

    let rows = response.data && response.data.data;
    let dataTypes = response.data && response.data.dataTypes;
    let roleTypes = response.data && response.data.rolTypes;
    let activeTypes = response.data && response.data.activeTypes;
    let dateFormat = response.data && response.data.dateFormat;

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: {
        rows,
        dataTypes,
        roleTypes,
        activeTypes,
        dateFormat,
      }
    });
    dispatch(openSnackbarAction({ msg: "Definición de consulta modificada con éxito", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteQueryDefinitionAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteQueryDefinitionAPI(data);

    let rows = response.data && response.data.data;
    let dataTypes = response.data && response.data.dataTypes;
    let roleTypes = response.data && response.data.rolTypes;
    let activeTypes = response.data && response.data.activeTypes;
    let dateFormat = response.data && response.data.dateFormat;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: {
        rows,
        dataTypes,
        roleTypes,
        activeTypes,
        dateFormat,
      }
    });

    dispatch(openSnackbarAction({ msg: "Definición de consulta eliminada con éxito", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED });
    errorHandler(e, dispatch);
  }
};

export const duplicateQueryDefinitionAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.DUPLICATE });
  try {
    let response = await duplicateQueryDefinitionAPI(data);

    let rows = response.data && response.data.data;
    let dataTypes = response.data && response.data.dataTypes;
    let roleTypes = response.data && response.data.rolTypes;
    let activeTypes = response.data && response.data.activeTypes;
    let dateFormat = response.data && response.data.dateFormat;

    dispatch({
      type: actionTypes.DUPLICATE_FULFILLED,
      payload: {
        rows,
        dataTypes,
        roleTypes,
        activeTypes,
        dateFormat,
      }
    });
    dispatch(openSnackbarAction({ msg: "Definición de consulta duplicada con éxito", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DUPLICATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

