import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { Grid } from "@material-ui/core";
import Item from "./ratingScale/item";
import ABMLayout from "@icarius-common/abmComponents/abmLayout";
import paths from "@icarius-localization/paths";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import useRatingScales from "./useRatingScales";

const RatingScales = ({ history }) => {

  const {
    color,
    gridTheme,
    data,
    isLoading,
    typeList,
    createRatingScaleIsOpen,
    setCreateRatingScaleIsOpen,
  } = useRatingScales();

  const customItems = (
    <>
      <CustomIconButton
        title={getLocalizedString("goBack")}
        onClick={() => history.push(paths.performanceDefinition)}
        type={"goBack"}
      />
    </>
  )

  const ratingScaleNames = data?.map(ratingScale => ratingScale.name) || [];
  const ratingScaleCodes = data?.map(ratingScale => ratingScale.code) || [];

  const getDataIsEmpty = () => {
    return !data || data.length === 0;
  }

  return (
    <ABMLayout
      title={"Escalas para calificaciones"}
      isLoading={isLoading}
      showCreateButton={!createRatingScaleIsOpen}
      createButtonTitle={"Nuevo tipo de escala"}
      createButtonOnClick={() => setCreateRatingScaleIsOpen(true)}
      customItems={[customItems]}
      useCustomItemsEnd
      showNoData={getDataIsEmpty()}
      noDataTitle={"No se encontraron tipos de escala"}
      noDataCreateTitle={"Nuevo tipo de escala"}
      noDataOnClick={() => setCreateRatingScaleIsOpen(true)}
    >
      {
        createRatingScaleIsOpen &&
        <Grid container item xs={12} style={{ margin: "10px 0px" }}>
          <Item
            data={null}
            color={color}
            closeNewRatingScale={() => setCreateRatingScaleIsOpen(false)}
            isLoading={isLoading}
            ratingScaleNames={ratingScaleNames}
            ratingScaleCodes={ratingScaleCodes}
            gridTheme={gridTheme}
            typeList={typeList}
          />
        </Grid>
      }
      {
        data.map((item) =>
          <Grid container item xs={12} style={{ margin: "10px 0px" }} key={item.code}>
            <Item
              data={item}
              color={color}
              isLoading={isLoading}
              ratingScaleNames={ratingScaleNames}
              ratingScaleCodes={ratingScaleCodes}
              gridTheme={gridTheme}
              typeList={typeList}
            />
          </Grid>
        )
      }
    </ABMLayout>
  )
}

export default RatingScales;