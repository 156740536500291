import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoading, getData } from "../selectors";
import { RESET_STATE } from "../actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import {
  deleteRatingAction,
  duplicateRatingAction,
  getRatingsAction,
} from "../actions";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getLocalizedString } from "@icarius-localization/strings";

const useRatings = (groupCode) => {

  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [duplicateDialogIsOpen, setDuplicateDialogIsOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null); //seleccionado para editar

  const dispatch = useDispatch();
  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);

  useEffect(() => {
    dispatch(getRatingsAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch, groupCode])

  const handleOpenDeleteDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: "¿Desea eliminar el período?",
      onConfirm: () => handleDelete(documents[0]),
    }));
  }

  const handleOpenCreateDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length > 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro o ninguno', severity: "warning" }));
      return;
    }

    setSelectedDocument(documents.length === 1 ? documents[0] : null);
    setCreateDialogIsOpen(true);
  }

  const handleOpenEditDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    setSelectedDocument(documents[0]);
    setEditDialogIsOpen(true);
  }

  const handleOpenDuplicateDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    setSelectedDocument(documents[0]);
    setDuplicateDialogIsOpen(true);
  }

  const handleCloseCreateEditDuplicateDialog = () => {
    setCreateDialogIsOpen(false);
    setEditDialogIsOpen(false);
    setDuplicateDialogIsOpen(false);
    setSelectedDocument(null);
  }

  const handleDelete = (document) => {
    dispatch(deleteRatingAction({ Período: document['Período'] }));
  }

  const handleDuplicate = (newValue) => {
    dispatch(duplicateRatingAction({ Período: newValue, ref: selectedDocument['Período'] }))
      .then(res => {
        if (res?.status === 200) {
          handleCloseCreateEditDuplicateDialog();
        }
      })
  }

  const state = {
    duplicateDialogIsOpen,
    editDialogIsOpen,
    createDialogIsOpen,
    selectedDocument,
  }

  const handlers = {
    handleDelete,
    handleDuplicate,
    handleOpenDeleteDialog,
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleOpenDuplicateDialog,
    handleCloseCreateEditDuplicateDialog,
  }

  return {
    data,
    isLoading,
    state,
    handlers
  }
}

export default useRatings;