import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { createRatingAction, modifyRatingAction } from "@icarius-pages/ratings/actions";
import { useDispatch } from "react-redux";

const gridStyle = { height: 75, padding: "0px 10px" };

const CreateEditDialog = (props) => {

  const {
    open,
    isLoading,
    data,
    handleClose,
  } = props;

  const dispatch = useDispatch();

  const create = (dataToSend) => {
    dispatch(createRatingAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(modifyRatingAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit,
  } = useHandleForm(data, create, modify, openValidationError);

  if (isLoading) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"md"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {isCreate ? "Crear índice" : "Editar índice"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Grid container item xs={12}>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  disabled={!isCreate}
                  required
                  style={{ marginTop: 0, marginBottom: 0, width: "100%" }}
                  label={'Período'}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  invalidDateMessage=""
                  format="yyyy/MM"
                  margin="normal"
                  value={formData['Período']}
                  onChange={(e) => setFormValue(e, 'Período')}
                  views={["year", "month"]}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
              <TextField
                type='number'
                fullWidth
                margin={"none"}
                label={'IPC'}
                value={formData['IPC']}
                onChange={(e) => setFormValue(e.target.value, "IPC")}
                inputProps={{ min: "0", step: "0.001" }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
              <TextField
                required
                type='number'
                fullWidth
                margin={"none"}
                label={'UF Último día mes anterior'}
                value={formData['UF Último día mes anterior']}
                onChange={(e) => setFormValue(e.target.value, "UF Último día mes anterior")}
                inputProps={{ min: "0", step: "0.001" }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
              <TextField
                required
                type='number'
                fullWidth
                margin={"none"}
                label={'UF Último día mes actual'}
                value={formData['UF Último día mes actual']}
                onChange={(e) => setFormValue(e.target.value, "UF Último día mes actual")}
                inputProps={{ min: "0", step: "0.001" }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
              <TextField
                type='number'
                fullWidth
                margin={"none"}
                label={'UF del día 9'}
                value={formData['UF del día 9']}
                onChange={(e) => setFormValue(e.target.value, "UF del día 9")}
                inputProps={{ min: "0", step: "0.001" }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
              <TextField
                required
                type='number'
                fullWidth
                margin={"none"}
                label={'UTM'}
                value={formData['UTM']}
                onChange={(e) => setFormValue(e.target.value, "UTM")}
                inputProps={{ min: "0", step: "0.001" }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
              <TextField
                required
                type='number'
                fullWidth
                margin={"none"}
                label={'Tope imponible'}
                value={formData['Tope imponible']}
                onChange={(e) => setFormValue(e.target.value, "Tope imponible")}
                inputProps={{ min: "0", step: "0.001" }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
              <TextField
                required
                type='number'
                fullWidth
                margin={"none"}
                label={'Tope imponible seguro cesantía'}
                value={formData['Tope imponible seguro cesantía']}
                onChange={(e) => setFormValue(e.target.value, "Tope imponible seguro cesantía")}
                inputProps={{ min: "0", step: "0.001" }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
              <TextField
                required
                type='number'
                fullWidth
                margin={"none"}
                label={'Salario mínimo'}
                value={formData['Salario mínimo']}
                onChange={(e) => setFormValue(e.target.value, "Salario mínimo")}
                inputProps={{ min: "0", step: "0.001" }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
              <TextField
                type='number'
                fullWidth
                margin={"none"}
                label={'Salario Grado 1-A'}
                value={formData['Salario Grado 1-A']}
                onChange={(e) => setFormValue(e.target.value, "Salario Grado 1-A")}
                inputProps={{ min: "0", step: "0.001" }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
              <TextField
                type='number'
                fullWidth
                margin={"none"}
                label={'Salario no remunerado'}
                value={formData['Salario no remunerado']}
                onChange={(e) => setFormValue(e.target.value, "Salario no remunerado")}
                inputProps={{ min: "0", step: "0.001" }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
              <TextField
                type='number'
                fullWidth
                margin={"none"}
                label={'Salario vital'}
                value={formData['Salario vital']}
                onChange={(e) => setFormValue(e.target.value, "Salario vital")}
                inputProps={{ min: "0", step: "0.001" }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
              <TextField
                type='number'
                fullWidth
                margin={"none"}
                label={'Dólar'}
                value={formData['Dólar']}
                onChange={(e) => setFormValue(e.target.value, "Dólar")}
                inputProps={{ min: "0", step: "0.001" }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
              <TextField
                type='number'
                fullWidth
                margin={"none"}
                label={'Euro'}
                value={formData['Euro']}
                onChange={(e) => setFormValue(e.target.value, "Euro")}
                inputProps={{ min: "0", step: "0.001" }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
          <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default CreateEditDialog;
