import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Typography,
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";

const DeleteDialog = (props) => {

  const { open, ratingScale, handleDelete, handleClose } = props;

  const handleAccept = () => {
    handleDelete();
    handleClose();
  }

  const getString = () => {
    if (!ratingScale) return getLocalizedString("confirmDeleteRatingScale");
    return getLocalizedString("ratingScaleDeleteItem").replace("{name}", ratingScale.name);
  }

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      maxWidth={"sm"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {"Eliminar tipo de escala"}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <Typography className="whiteText" variant="h6" gutterBottom>
          {getString()}
        </Typography>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
        <ButtonDialogAction onClick={handleAccept} isAccept text={getLocalizedString("delete")} />
      </DialogActions>
    </Dialog>
  );
}

export default DeleteDialog;
